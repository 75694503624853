import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap"
import SimpleBar from "simplebar-react"

//Import images
// import avatar3 from "../../../assets/images/users/avatar-3.jpg";
// import avatar4 from "../../../assets/images/users/avatar-4.jpg";

//i18n
import { withTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { createSelector } from "reselect"
import { getAllNotificationList } from "store/actions"

const NotificationDropdown = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("authUser")))
  const dispatch = useDispatch()

  const selectNotificationsState = state => state.Notifications
  const notificationSelector = createSelector(
    selectNotificationsState,
    notifications => ({
      loading: notifications?.loading,
      notifications: notifications.allNotifications.data,
    })
  )

  const { notifications, loading } = useSelector(notificationSelector)

  useEffect(() => {
    if (user) {
      const value = {
        portal_type: "2",
        target_user_id: user.id,
      }

      // Function to dispatch the API call
      const fetchNotifications = () => {
        dispatch(getAllNotificationList(value))
      }

      // Initial call to fetch notifications when component mounts
      fetchNotifications()

      // Set up an interval to fetch notifications every 30 seconds
      const intervalId = setInterval(() => {
        fetchNotifications()
      }, 15000) // 30,000 ms = 30 seconds

      // Cleanup interval when component unmounts
      return () => clearInterval(intervalId)
    }
  }, [user, dispatch])

  const hasUnreadNotifications = notifications?.some(
    notif => notif.is_read === 0
  )

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon position-relative"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className={notifications?.filter(notif => notif.is_read === 0).length === 0 ? "bx bx-bell" : "bx bx-bell bx-tada"} />
          {hasUnreadNotifications && (
            <span className="badge bg-danger rounded-pill">
              {notifications?.filter(notif => notif.is_read === 0).length}
            </span>
          )}{" "}
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"> {props.t("Notifications")} </h6>
              </Col>
            </Row>
          </div>

          <SimpleBar style={{ height: "230px" }}>
            {notifications?.length > 0 ? (
              notifications
                .slice(0, 6) // Limit to 6 notifications
                .map((notification, index) => (
                  <Link
                    to={`/notifications?id=${notification.id}`}
                    className="text-reset notification-item"
                    key={index}
                    onClick={() => {
                      setMenu(!menu)
                    }}
                  >
                    <div
                      className="d-flex"
                      style={{
                        border: "1px solid rgba(220, 226, 233, 0.52)", // Corrected border color with rgba'
                      }}
                    >
                      {/* Avatar can be added here if needed */}
                      <div className="flex-grow-1">
                        <h6
                          className="mt-0 mb-1"
                          style={{
                            fontWeight:
                              notification.is_read === 0 ? "bold" : "normal",
                          }}
                        >
                          {notification.title}
                        </h6>
                        <div className="font-size-12 text-muted">
                          <p className="mb-1">{notification.message}</p>
                          <p className="mb-0">
                            <i className="mdi mdi-clock-outline" />
                            {notification.sent_on}{" "}
                            {/* Assuming this contains time info */}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Link>
                ))
            ) : (
              <p className="text-muted text-center">
                No notifications available
              </p>
            )}
          </SimpleBar>
          <div className="p-2 border-top d-grid">
            <Link
              className="btn btn-sm btn-link font-size-14 text-center"
              to="/notifications"
              onClick={() => {
                setMenu(!menu)
              }}
            >
              <i className="mdi mdi-arrow-right-circle me-1"></i>{" "}
              <span key="t-view-more">{props.t("View More..")}</span>
            </Link>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

export default withTranslation()(NotificationDropdown)

NotificationDropdown.propTypes = {
  t: PropTypes.any,
}
