export const API_URL = 'https://vipmalldemobackend.erpca.shop/public';
// export const API_URL = `http://localhost:8000`
// export const API_URL = `https://api.v1malls.com`

export const LOGIN = `${API_URL}/api/client-login`
export const LOGOUT_USER = `${API_URL}/api/client-logout`
export const UPDATE_PASS = `${API_URL}/api/update-client-password`
export const FORGET_PASSWORD = `${API_URL}/api/forget-password-client`

//COUNTRY STATE CITY API
export const GET_CITIES = `${API_URL}/api/cities`
export const GET_STATES = `${API_URL}/api/states`
export const GET_COUNTRIES = `${API_URL}/api/countries`

export const GET_PROJECTUNITS = `${API_URL}/api/show-all-client-project-unit`
export const GET_PROJECTUNIT_DETAIL = `${API_URL}/api/show-client-project-unit`
export const ADD_NEW_PROJECTUNIT = `${API_URL}`
export const DELETE_PROJECTUNIT = `${API_URL}`
export const UPDATE_PROJECTUNIT = `${API_URL}`

export const GET_SERVICE_REQUESTS = `${API_URL}/api/list-service-request`
export const GET_PENDING_REQUESTS = `${API_URL}/api/list-pending-service-requests`
export const GET_SERVICE_REQUEST_TYPE = `${API_URL}/api/service-request-types`
export const GET_SERVICE_REQUEST_DETAIL = `${API_URL}/api/get-service-request`
export const ADD_NEW_SERVICE_REQUEST = `${API_URL}/api/create-service-request`
export const DELETE_SERVICE_REQUEST = `${API_URL}/api/delete-service-request`
export const UPDATE_SERVICE_REQUEST = `${API_URL}`
export const GET_PROJECT_UNIT_CATEGORY = `${API_URL}/api/list-project-unit-category`
export const GET_MAINTENANCE_ISSUES = `${API_URL}/api/list-client-maintenance-issues`
export const GET_CLIENT_INVOICES = `${API_URL}/api/list-bills`
export const GET_SUPPORT_TICKET = `${API_URL}/api/list-support-ticket`
export const GET_ALL_PROJECTS = `${API_URL}/api/list-preffered-project-management`
export const GET_PROJECT_UNIT = `${API_URL}/api/list-client-project-unit-in-service-request`
export const GET_CLIENT_PROJECT_FOR_SERVICE_REQUEST = `${API_URL}/api/project-management-list-for-service-request`

export const GET_PENDING_BILL_LIST = `${API_URL}/api/list_all_client-pending-bill-data`
export const GET_BILL_HISTORY_LIST = `${API_URL}/api/list_all_client-bill-data`
export const GET_BILL_RECEIPT_LIST = `${API_URL}/api/list-bill-payments-by-customerId`
export const GET_RECEIPT_PREVIEW = `${API_URL}/api/get-receipt-preview-list-in-customer-portal`
export const GET_CREDIT_NOTE_LIST = `${API_URL}/api/list-credit-note`

export const GET_INVOICE_DATA = `${API_URL}/api/get-client-billing-data-for-invoice`
export const GET_LATEST_INVOICE = `${API_URL}/api/latest-billing-with-components`
export const GET_PREVIOUS_INVOICES_LIST = `${API_URL}/api/list-billing-with-components`
export const GET_SERVICE_REQUEST = `${API_URL}/api/get-service-request-for-dashboard`

export const GET_CLIENT_DETAIL = `${API_URL}/api/get-client-in-client-panel`
export const GET_LASTEST_PROJECT_UNIT = `${API_URL}/api/list-customer-project-units`

//dashboard graph apis

export const GET_WATER_CONSUMPTION = `${API_URL}/api/list-all-count-water-graph`
export const GET_ELECTRICITY_CONSUMPTION = `${API_URL}/api/list-all-count-electricity-graph`
export const GET_GAS_CONSUMPTION = `${API_URL}/api/list-all-count-gas-graph`
export const GET_DG_CONSUMPTION = `${API_URL}/api/list-all-count-dg-graph`


//
//FILTERS FOR CLIENT PAYMENT FOR HISTORY

export const GET_COMPANY_FILTER_FOR_HISTORY = `${API_URL}/api/list-companies-by-projectId`
export const GET_FINANCIAL_YEAR_FILTER_FOR_HISTORY = `${API_URL}/api/get-financial-year-list1`
export const GET_COMPANY_LOCATION_FILTER_FOR_HISTORY = `${API_URL}/api/get-location-list1`
export const GET_CUSTOMER_FILTER_FOR_HISTORY = `${API_URL}/api/list-client-project-units-by-projectId`
export const GET_PROJECT_FILTER_FOR_HISTORY = `${API_URL}/api/list-projects-by-clientId`

//FILTERS FOR CLIENT PAYMENT FOR PENDING LIST

export const GET_COMPANY_FILTER_FOR_PENDING = `${API_URL}/api/get-company-list1`
export const GET_FINANCIAL_YEAR_FILTER_FOR_PENDING = `${API_URL}/api/get-financial-year-list1`
export const GET_COMPANY_LOCATION_FILTER_FOR_PENDING = `${API_URL}/api/get-location-list1`

export const GET_CUSTOMER_FILTER_FOR_PENDING = `${API_URL}/api/list-client-project-units-by-projectId`
export const GET_PROJECT_FILTER_FOR_PENDING = `${API_URL}/api/list-projects-by-clientId`

//conumption filter
export const GET_CUSTOMER_FILTER_FOR_CONSUMPTION = `${API_URL}/api/list-customer-for-project`
export const GET_PROJECT_FILTER_FOR_CONSUMPTION = `${API_URL}/api/list-project-management-for-customer`
export const GET_UNIT_FOR_CONSUMPTION = `${API_URL}/api/list-client-project-unit`
export const GET_CONSUMPTION_FILTER = `${API_URL}/api/get-customer-consumption-data`

//dashboard
export const GET_DASHBOARD_DATA = `${API_URL}/api/all-count-client-dashboard-data`

//project list
export const GET_PROJECT_IN_CUSTOMER = `${API_URL}/api/project-management-list-for-service-request`

//Notification
export const GET_ALL_NOTIFICATION_LIST = `${API_URL}/api/fetch-notifications-for-client`
export const IS_READ_NOTIFICATION = `${API_URL}/api/mark-as-read-for-client`
export const DELETE_NOTIFICATION = `${API_URL}/api/delete-notifications-for-client`
