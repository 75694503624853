import { all, fork } from "redux-saga/effects"

//public
import AuthSaga from "./login/saga"
import LayoutSaga from "./layout/saga"
import commonSaga from "./common/saga"
import ProfileSaga from "./profile/saga"
import projectUnitsSaga from "./projectUnits/saga"
import serviceRequestsSaga from "./serviceRequests/saga"
import billAndPaymentsSaga from "./bills&payment/saga"
import customerProfilePreview from "./customerProfilePreview/saga"
import dashboardSaga from "./dashboard/saga"
import forgetPasswordSaga from "./forgetpwd/saga"
import VendorPaymentSaga from "./bills&payment/paymentFilterForHistory/saga"
import ConsumptionReportSaga from "./consumptionReport/saga"
import notificationSaga from "./notifications/saga"

export default function* rootSaga() {
  yield all([
    fork(AuthSaga),
    fork(forgetPasswordSaga),
    fork(LayoutSaga),
    fork(commonSaga),
    fork(ProfileSaga),
    fork(projectUnitsSaga),
    fork(serviceRequestsSaga),
    fork(billAndPaymentsSaga),
    fork(customerProfilePreview),
    fork(dashboardSaga),
    fork(VendorPaymentSaga),
    fork(ConsumptionReportSaga),
    fork(notificationSaga),
  ])
}
