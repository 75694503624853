import { call, put, takeEvery } from "redux-saga/effects"
import Swal from "sweetalert2"
//  Redux States
import {
  GET_SERVICE_REQUESTS,
  GET_PENDING_REQUESTS,
  GET_SERVICE_REQUEST_DETAIL,
  ADD_NEW_SERVICE_REQUEST,
  DELETE_SERVICE_REQUEST,
  UPDATE_SERVICE_REQUEST,
  GET_PROJECT_UNIT_CATEGORY,
  GET_MAINTENANCE_ISSUES,
  GET_CLIENT_INVOICES,
  GET_SUPPORT_TICKET,
  GET_SERVICE_REQUEST_TYPE,
  GET_ALL_PROJECTS,
  GET_PROJECT_UNIT,
  GET_CLIENT_PROJECT_FOR_SERVICE_REQUEST,
} from "./actionTypes"
import {
  getServiceRequestsSuccess,
  getServiceRequestsFail,
  getPendingRequestsSuccess,
  getPendingRequestsFail,
  getServiceRequestDetailSuccess,
  getServiceRequestDetailFail,
  addServiceRequestFail,
  addServiceRequestSuccess,
  updateServiceRequestSuccess,
  updateServiceRequestFail,
  deleteServiceRequestSuccess,
  deleteServiceRequestFail,
  getProjectUnitCategorySuccess,
  getProjectUnitCategoryFail,
  getSupportTicketsSuccess,
  getSupportTicketsFail,
  getClientInvoicesSuccess,
  getClientInvoicesFail,
  getMaintenanceIssuesSuccess,
  getMaintenanceIssuesFail,
  getServiceRequestTypeSuccess,
  getServiceRequestTypeFail,
  removePreviousResponse,
  getAllProjectsSuccess,
  getAllProjectsFail,
  getClientProjectUnitSuccess,
  getClientProjectUnitFail,
  getClientProjectSuccess,
  getClientProjectFail,
} from "./actions"

import {
  getServiceRequests,
  getPendingRequests,
  getServiceRequestDetails,
  addNewServiceRequest,
  updateServiceRequest,
  deleteServiceRequest,
  getMaintenanceIssues,
  getProjectUnitCategory,
  getClientInvoices,
  getSupportTicket,
  getServiceRequestType,
  getAllProjects,
  getProjectUnit,
  getClientProject,
} from "helpers/backend"

function* fetchServiceRequests({ value }) {
  try {
    const response = yield call(getServiceRequests, value)
    yield put(getServiceRequestsSuccess(response))
  } catch (error) {
    yield put(getServiceRequestsFail(error))
  }
}
function* fetchPendingRequests({ id }) {
  try {
    const response = yield call(getPendingRequests, id)
    yield put(getPendingRequestsSuccess(response))
  } catch (error) {
    yield put(getPendingRequestsFail(error))
  }
}
function* fetchServiceRequestType() {
  try {
    const response = yield call(getServiceRequestType)
    yield put(getServiceRequestTypeSuccess(response))
  } catch (error) {
    yield put(getServiceRequestTypeFail(error))
  }
}

function* fetchServiceRequestDetail({ serviceRequestId }) {
  try {
    const response = yield call(getServiceRequestDetails, serviceRequestId)
    yield put(getServiceRequestDetailSuccess(response))
  } catch (error) {
    yield put(getServiceRequestDetailFail(error))
  }
}

function* onUpdateServiceRequest({ payload: { id, serviceRequest } }) {
  try {
    const response = yield call(updateServiceRequest, id, serviceRequest)
    yield put(updateServiceRequestSuccess(response))
  } catch (error) {
    yield put(updateServiceRequestFail(error))
  }
}

function* onDeleteServiceRequest({ serviceRequest }) {
  try {
    const response = yield call(deleteServiceRequest, serviceRequest)
    yield put(deleteServiceRequestSuccess(response))
  } catch (error) {
    yield put(deleteServiceRequestFail(error))
  }
}

function* onAddNewServiceRequest({
  payload: { clientId, serviceRequest, history },
}) {
  try {
    const loadingSwal = Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      showConfirmButton: false,
      onBeforeOpen: () => {
        Swal.showLoading()
      },
    })
    const response = yield call(addNewServiceRequest, clientId, serviceRequest)
    yield put(addServiceRequestSuccess(response))
    if (response.status === true) {
      loadingSwal.close()

      Swal.fire({
        title: "Success",
        icon: "success",
        text: "Your Service Request has been Submitted.",
      }).then(result => {
        if (result.isConfirmed) {
          history("/service-requests/all-service-requests")
        }
      })
    } else {
      loadingSwal.close()

      Swal.fire({
        title: "failure",
        icon: "error",
        text: response.message,
      })
    }
  } catch (error) {
    loadingSwal.close()

    yield put(addServiceRequestFail(error))
    Swal.fire({
      title: "failure",
      icon: "error",
      text: error.response.data.message,
    })
  }
}
//DEPENDENCY LIST
function* fetchProjectUnitCategory() {
  try {
    const response = yield call(getProjectUnitCategory)
    yield put(getProjectUnitCategorySuccess(response))
  } catch (error) {
    yield put(getProjectUnitCategoryFail(error))
  }
}

function* fetchMaintenanceIssues() {
  try {
    const response = yield call(getMaintenanceIssues)
    yield put(getMaintenanceIssuesSuccess(response))
  } catch (error) {
    yield put(getMaintenanceIssuesFail(error))
  }
}

function* fetchClientInvoices({ id }) {
  try {
    const response = yield call(getClientInvoices, id)
    yield put(getClientInvoicesSuccess(response))
  } catch (error) {
    yield put(getClientInvoicesFail(error))
  }
}

function* fetchSupportTicket() {
  try {
    const response = yield call(getSupportTicket)
    yield put(getSupportTicketsSuccess(response))
  } catch (error) {
    yield put(getSupportTicketsFail(error))
  }
}

function* fetchAllProjects() {
  try {
    const response = yield call(getAllProjects)
    yield put(getAllProjectsSuccess(response))
  } catch (error) {
    yield put(getAllProjectsFail(error))
  }
}

function* fetchProjectUnit({ values }) {
  console.log(values)
  try {
    const response = yield call(getProjectUnit, values)
    yield put(getClientProjectUnitSuccess(response))
  } catch (error) {
    yield put(getClientProjectUnitFail(error))
  }
}

function* fetchClientProject({ id }) {
  try {
    const response = yield call(getClientProject, id)
    yield put(getClientProjectSuccess(response))
  } catch (error) {
    yield put(getClientProjectFail(error))
  }
}

function* serviceRequestsSaga() {
  yield takeEvery(GET_SERVICE_REQUESTS, fetchServiceRequests)
  yield takeEvery(GET_PENDING_REQUESTS, fetchPendingRequests)
  yield takeEvery(GET_SERVICE_REQUEST_TYPE, fetchServiceRequestType)
  yield takeEvery(GET_SERVICE_REQUEST_DETAIL, fetchServiceRequestDetail)
  yield takeEvery(ADD_NEW_SERVICE_REQUEST, onAddNewServiceRequest)
  yield takeEvery(UPDATE_SERVICE_REQUEST, onUpdateServiceRequest)
  yield takeEvery(DELETE_SERVICE_REQUEST, onDeleteServiceRequest)
  yield takeEvery(GET_PROJECT_UNIT_CATEGORY, fetchProjectUnitCategory)
  yield takeEvery(GET_MAINTENANCE_ISSUES, fetchMaintenanceIssues)
  yield takeEvery(GET_CLIENT_INVOICES, fetchClientInvoices)
  yield takeEvery(GET_SUPPORT_TICKET, fetchSupportTicket)
  yield takeEvery(GET_ALL_PROJECTS, fetchAllProjects)
  yield takeEvery(GET_PROJECT_UNIT, fetchProjectUnit)
  yield takeEvery(GET_CLIENT_PROJECT_FOR_SERVICE_REQUEST, fetchClientProject)
}

export default serviceRequestsSaga
